import React, { useEffect, useState } from "react";
import VideoWidget from "./VideoWidget";
import ChatWidget from "./ChatWidget";
import axios from "axios";
import { HashRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import Home from "./Home";
import { ClientData } from "./Types";
import { kf1, kf2, kf3, kf4, kf5, kf6 } from "./Styles";
import PageNotFound from "./404";

export const App = ({ clientId }: { clientId?: string | number }) => {
  const [clientData, setClientData] = useState<ClientData>();
  const [toastifyCss, setToastifyCss] = useState<string | null>(null);

  const widgetPosition = clientData?.ui.position;
  const chatAppId = clientData?.ui.chatWidget.intercomId;
  const brand = clientData?.ui.chatWidget.brand;

  useEffect(() => {
    const fetchData = async () => {
      let url = `${process.env.REACT_APP_API_URL}/client/${clientId}`;
      try {
        const response = await axios.get(url);
        setClientData(response.data);
      } catch (error) {
        console.error(
          "Unexpected error while fetching client data. Error: ",
          error.message
        );
      }
    };

    fetchData();
  }, [clientId]);

  //loading styles
  useEffect(() => {
    if (clientData) {
      const cssContent = `
      #smp-widget * {
        --w-primary-color: ${clientData.ui.colors.background.primary};
        --w-secondary-color: ${clientData.ui.colors.background.secondary};
        --w-tertiary-color: ${clientData.ui.colors.background.tertiary};
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 400;
        font-style: normal;
        box-sizing: border-box;
        font-size: 16px;
      }

      #smp-widget *::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }

      #w-ig-modal * {
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 400;
        font-style: normal;
        box-sizing: border-box;
      }

      #w-amenity-list li {
        list-style-position: outside;
        font-weight: 300;
        padding: 2px 1px;
      }

      .w-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: none;
        animation: widgetloader 4s linear infinite;
        webkit-clip-path: polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13%, 96% 13.3%, 98% 14%, 99% 15%, 99.8% 17%, 100% 18%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%);
        clip-path: polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13%, 96% 13.3%, 98% 14%, 99% 15%, 99.8% 17%, 100% 18%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%);
      }
      
      .w-loader:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--w-primary-color);
        clip-path: inherit;
      }

      .w-floorplan-details {
        color: #3c4858;
        font-size: 14px;
        margin-bottom: 3px;
      }

      .w-primary-button {
        width: 100%;
        padding: 10px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
        pointer-events: auto;
        transition-duration: 150ms;
        border-radius: 9999px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .w-menu-container { 
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
      }

      .w-formik-container {
        display: flex;
        flex-direction: column;
        height: auto;
        border-radius: 10px;
        margin-top: 10px;
        padding: 10px;
      }

      .w-input-formik-field {
        height: 40px;
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 10px;
        padding-left: 10px;
        color: #000;
        font-weight: 400;
        box-shadow: 0 2px 6px -1px rgb(0 0 0 / 0.2), 0 2px 3px -2px rgb(0 0 0 / 0.1);
        border: none;
      }

      .w-input-formik-field:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(134, 157, 173, 0.764);
      }

      .w-formik-container span {
        color: rgb(216, 23, 23);
        font-style: italic;
        font-size: smaller;
        padding-left: 5px;
      }

      .w-formik-label {
        font-size: 14px;
        padding-left: 5px;
      }

      .calendly-inline-widget, .simplebar-content-wrapper {
        overflow: hidden !important;
      }

      .carousel-container::-webkit-scrollbar {
        display: none;
      }

      .w-filter-dropshadow {
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
      }

      ${kf1}
      ${kf2}
      ${kf3}
      ${kf4}
      ${kf5}
      ${kf6}
    `;

      injectStyle(cssContent);
    }
  }, [clientData]);

  useEffect(() => {
    const loadToastify = async () => {
      try {
        // Fetch the CSS file
        const response = await fetch(
          "https://unpkg.com/react-toastify/dist/ReactToastify.css"
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get the CSS text
        const cssText = await response.text();
        setToastifyCss(cssText);
      } catch (error) {
        console.error("Failed to load React Toastify CSS:", error);
      }
    };
    loadToastify();
  }, []);

  useEffect(() => {
    if (toastifyCss) {
      // Create a <style> element and set its content
      const styleElement = document.createElement("style");
      document.getElementById("smp-widget")?.appendChild(styleElement);
      styleElement.innerHTML = toastifyCss;
      // styleElement.appendChild(document.createTextNode(cssText));

      // Append the <style> element to the document head
      // document.head.appendChild(styleElement);
    }
  }, [toastifyCss]);

  //load intercom at the first render
  useEffect(() => {
    window.intercomSettings = {
      app_id: chatAppId || "",
      brand: brand,
      hide_default_launcher: true,
      alignment: widgetPosition,
      background_color: clientData?.ui.colors.background.primary,
      action_color: clientData?.ui.colors.background.primary,
    };

    const loadIntercom = () => {
      if (!window.Intercom) {
        const intercomScript = document.createElement("script");
        intercomScript.type = "text/javascript";
        intercomScript.async = true;
        intercomScript.src = "https://widget.intercom.io/widget/m5bvaec7";
        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode?.insertBefore(intercomScript, firstScript);

        intercomScript.onload = () => {
          if (window.Intercom) {
            window.Intercom("reattach_activator");
            window.Intercom("update", window.intercomSettings);
          }
        };
      } else {
        window.Intercom("reattach_activator");
        window.Intercom("update", window.intercomSettings);
      }
    };

    loadIntercom();

    return () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, [chatAppId, brand, widgetPosition, clientData]);

  if (clientData && clientData?.isActive) {
    return (
      <div id="smp-widget">
        <Router>
          <Routes>
            <Route path="/" element={<Home clientData={clientData} />} />
            {clientData?.ui.videoWidget.isActive && (
              <Route
                path="/video/*"
                element={<VideoWidget clientData={clientData} />}
              />
            )}
            {clientData?.ui.chatWidget.isActive && (
              <Route
                path="/chat"
                element={<ChatWidget clientData={clientData} />}
              />
            )}
            <Route
              path="*"
              element={<PageNotFound clientData={clientData} />}
            />
          </Routes>
          <Outlet />
        </Router>
      </div>
    );
  }
};

function injectStyle(cssContent) {
  let stylesheet = document.createElement("style");
  document.getElementById("smp-widget")?.appendChild(stylesheet);
  stylesheet.innerHTML = cssContent;
}

export default App;
